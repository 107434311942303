.header-typography {
  flex: 1;
}

.header-logo {
  flex: 1;
  color: gold;
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
}