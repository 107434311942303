@import url('https://fonts.googleapis.com/css2?family=Jost:wght@900&display=swap');

.coin-name {
  font-family: Jost;
  
}

@media screen and (max-width: 768px) {
  .coin-name {
    font-size: 39px;
  }

  .img-coin-page{
    max-width: 130px;
    max-height: 130px;
  }
  .coin-date{
    font-size: 22px;
  }
  .coin-date-date{
    font-size: 22px;
  }
}