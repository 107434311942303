.coins-table {
  margin: 18px;
  color: white;
}

.coins-tablerow {
  background-color: #2a2b31;
  transition-duration: 0.3s;
  
  &:hover {
    background-color: rgba(255, 99, 132, 1);
  }
}

.coins-pagination {
  padding: 20px;
  width: 100%;
  display: flex;
  justify-content: center;

  & .MuiPaginationItem-root {
    color: gold;
  }
}